// COMPONENTS
import PhotosGallery from '../components/PhotosGallery';

function Home() {
  return (
    <div className='Home'>
      <PhotosGallery />
    </div>
  );
}

export default Home;
