import { v4 as uuidv4 } from 'uuid';

const photos = {
  vladivostok: [
    // VLADIVOSTOK
    {
      name: 'vladivostok-01',
      photoSmall: '../img/photos/vladivostok/01-vladivostok-small.jpg',
      photo: '../img/photos/vladivostok/01-vladivostok.jpg',
      key: uuidv4(),
      filter: 'vladivostok',
    },
    {
      name: 'vladivostok-02',
      photoSmall: '../img/photos/vladivostok/02-vladivostok-small.jpg',
      photo: '../img/photos/vladivostok/02-vladivostok.jpg',
      key: uuidv4(),
      filter: 'vladivostok',
    },
    {
      name: 'vladivostok-03',
      photoSmall: '../img/photos/vladivostok/03-vladivostok-small.jpg',
      photo: '../img/photos/vladivostok/03-vladivostok.jpg',
      key: uuidv4(),
      filter: 'vladivostok',
    },
    {
      name: 'vladivostok-04',
      photoSmall: '../img/photos/vladivostok/04-vladivostok-small.jpg',
      photo: '../img/photos/vladivostok/04-vladivostok.jpg',
      key: uuidv4(),
      filter: 'vladivostok',
    },
    {
      name: 'vladivostok-05',
      photoSmall: '../img/photos/vladivostok/05-vladivostok-small.jpg',
      photo: '../img/photos/vladivostok/05-vladivostok.jpg',
      key: uuidv4(),
      filter: 'vladivostok',
    },
    {
      name: 'vladivostok-06',
      photoSmall: '../img/photos/vladivostok/06-vladivostok-small.jpg',
      photo: '../img/photos/vladivostok/06-vladivostok.jpg',
      key: uuidv4(),
      filter: 'vladivostok',
    },
  ],
  sofia: [
    {
      name: 'sofia-01',
      photoSmall: '../img/photos/sofia/01-sofia-small.jpg',
      photo: '../img/photos/sofia/01-sofia.jpg',
      key: uuidv4(),
      filter: 'sofia',
    },
    {
      name: 'sofia-02',
      photoSmall: '../img/photos/sofia/02-sofia-small.jpg',
      photo: '../img/photos/sofia/02-sofia.jpg',
      key: uuidv4(),
      filter: 'sofia',
    },
    {
      name: 'sofia-03',
      photoSmall: '../img/photos/sofia/03-sofia-small.jpg',
      photo: '../img/photos/sofia/03-sofia.jpg',
      key: uuidv4(),
      filter: 'sofia',
    },
    {
      name: 'sofia-04',
      photoSmall: '../img/photos/sofia/04-sofia-small.jpg',
      photo: '../img/photos/sofia/04-sofia.jpg',
      key: uuidv4(),
      filter: 'sofia',
    },
    {
      name: 'sofia-05',
      photoSmall: '../img/photos/sofia/05-sofia-small.jpg',
      photo: '../img/photos/sofia/05-sofia.jpg',
      key: uuidv4(),
      filter: 'sofia',
    },
    {
      name: 'sofia-06',
      photoSmall: '../img/photos/sofia/06-sofia-small.jpg',
      photo: '../img/photos/sofia/06-sofia.jpg',
      key: uuidv4(),
      filter: 'sofia',
    },
  ],
  asia: [
    {
      name: 'asia-01',
      photoSmall: '../img/photos/asia/01-asia-small.jpg',
      photo: '../img/photos/asia/01-asia-large.jpg',
      key: uuidv4(),
      filter: 'asia',
    },
    {
      name: 'asia-02',
      photoSmall: '../img/photos/asia/02-asia-small.jpg',
      photo: '../img/photos/asia/02-asia-large.jpg',
      key: uuidv4(),
      filter: 'asia',
    },
    {
      name: 'asia-03',
      photoSmall: '../img/photos/asia/03-asia-small.jpg',
      photo: '../img/photos/asia/03-asia-large.jpg',
      key: uuidv4(),
      filter: 'asia',
    },
    {
      name: 'asia-04',
      photoSmall: '../img/photos/asia/04-asia-small.jpg',
      photo: '../img/photos/asia/04-asia-large.jpg',
      key: uuidv4(),
      filter: 'asia',
    },
    {
      name: 'asia-05',
      photoSmall: '../img/photos/asia/05-asia-small.jpg',
      photo: '../img/photos/asia/05-asia-large.jpg',
      key: uuidv4(),
      filter: 'asia',
    },
    {
      name: 'asia-06',
      photoSmall: '../img/photos/asia/06-asia-small.jpg',
      photo: '../img/photos/asia/06-asia-large.jpg',
      key: uuidv4(),
      filter: 'asia',
    },
    {
      name: 'asia-07',
      photoSmall: '../img/photos/asia/07-asia-small.jpg',
      photo: '../img/photos/asia/07-asia-large.jpg',
      key: uuidv4(),
      filter: 'asia',
    },
    {
      name: 'asia-08',
      photoSmall: '../img/photos/asia/08-asia-small.jpg',
      photo: '../img/photos/asia/08-asia-large.jpg',
      key: uuidv4(),
      filter: 'asia',
    },
    {
      name: 'asia-09',
      photoSmall: '../img/photos/asia/09-asia-small.jpg',
      photo: '../img/photos/asia/09-asia-large.jpg',
      key: uuidv4(),
      filter: 'asia',
    },
    {
      name: 'asia-10',
      photoSmall: '../img/photos/asia/10-asia-small.jpg',
      photo: '../img/photos/asia/10-asia-large.jpg',
      key: uuidv4(),
      filter: 'asia',
    },
    {
      name: 'asia-11',
      photoSmall: '../img/photos/asia/11-asia-small.jpg',
      photo: '../img/photos/asia/11-asia-large.jpg',
      key: uuidv4(),
      filter: 'asia',
    },
    {
      name: 'asia-12',
      photoSmall: '../img/photos/asia/12-asia-small.jpg',
      photo: '../img/photos/asia/12-asia-large.jpg',
      key: uuidv4(),
      filter: 'asia',
    },
    {
      name: 'asia-13',
      photoSmall: '../img/photos/asia/13-asia-small.jpg',
      photo: '../img/photos/asia/13-asia-large.jpg',
      key: uuidv4(),
      filter: 'asia',
    },
    {
      name: 'asia-14',
      photoSmall: '../img/photos/asia/14-asia-small.jpg',
      photo: '../img/photos/asia/14-asia-large.jpg',
      key: uuidv4(),
      filter: 'asia',
    },
    {
      name: 'asia-15',
      photoSmall: '../img/photos/asia/15-asia-small.jpg',
      photo: '../img/photos/asia/15-asia-large.jpg',
      key: uuidv4(),
      filter: 'asia',
    },
    {
      name: 'asia-16',
      photoSmall: '../img/photos/asia/16-asia-small.jpg',
      photo: '../img/photos/asia/16-asia-large.jpg',
      key: uuidv4(),
      filter: 'asia',
    },
    {
      name: 'asia-17',
      photoSmall: '../img/photos/asia/17-asia-small.jpg',
      photo: '../img/photos/asia/17-asia-large.jpg',
      key: uuidv4(),
      filter: 'asia',
    },
    {
      name: 'asia-18',
      photoSmall: '../img/photos/asia/18-asia-small.jpg',
      photo: '../img/photos/asia/18-asia-large.jpg',
      key: uuidv4(),
      filter: 'asia',
    },
    {
      name: 'asia-19',
      photoSmall: '../img/photos/asia/19-asia-small.jpg',
      photo: '../img/photos/asia/19-asia-large.jpg',
      key: uuidv4(),
      filter: 'asia',
    },
    {
      name: 'asia-20',
      photoSmall: '../img/photos/asia/20-asia-small.jpg',
      photo: '../img/photos/asia/20-asia-large.jpg',
      key: uuidv4(),
      filter: 'asia',
    },
    {
      name: 'asia-21',
      photoSmall: '../img/photos/asia/21-asia-small.jpg',
      photo: '../img/photos/asia/21-asia-large.jpg',
      key: uuidv4(),
      filter: 'asia',
    },
    {
      name: 'asia-22',
      photoSmall: '../img/photos/asia/22-asia-small.jpg',
      photo: '../img/photos/asia/22-asia-large.jpg',
      key: uuidv4(),
      filter: 'asia',
    },
    {
      name: 'asia-23',
      photoSmall: '../img/photos/asia/23-asia-small.jpg',
      photo: '../img/photos/asia/23-asia-large.jpg',
      key: uuidv4(),
      filter: 'asia',
    },
    {
      name: 'asia-24',
      photoSmall: '../img/photos/asia/24-asia-small.jpg',
      photo: '../img/photos/asia/24-asia-large.jpg',
      key: uuidv4(),
      filter: 'asia',
    },
    {
      name: 'asia-25',
      photoSmall: '../img/photos/asia/25-asia-small.jpg',
      photo: '../img/photos/asia/25-asia-large.jpg',
      key: uuidv4(),
      filter: 'asia',
    },
    {
      name: 'asia-26',
      photoSmall: '../img/photos/asia/26-asia-small.jpg',
      photo: '../img/photos/asia/26-asia-large.jpg',
      key: uuidv4(),
      filter: 'asia',
    },
    {
      name: 'asia-27',
      photoSmall: '../img/photos/asia/27-asia-small.jpg',
      photo: '../img/photos/asia/27-asia-large.jpg',
      key: uuidv4(),
      filter: 'asia',
    },
    {
      name: 'asia-28',
      photoSmall: '../img/photos/asia/28-asia-small.jpg',
      photo: '../img/photos/asia/28-asia-large.jpg',
      key: uuidv4(),
      filter: 'asia',
    },
  ],
  beijing: [
    {
      name: 'beijing-01',
      photoSmall: '../img/photos/beijing/01-beijing-small.jpg',
      photo: '../img/photos/beijing/01-beijing.jpg',
      key: uuidv4(),
      filter: 'beijing',
    },
    {
      name: 'beijing-02',
      photoSmall: '../img/photos/beijing/02-beijing-small.jpg',
      photo: '../img/photos/beijing/02-beijing.jpg',
      key: uuidv4(),
      filter: 'beijing',
    },
    {
      name: 'beijing-03',
      photoSmall: '../img/photos/beijing/03-beijing-small.jpg',
      photo: '../img/photos/beijing/03-beijing.jpg',
      key: uuidv4(),
      filter: 'beijing',
    },
    {
      name: 'beijing-04',
      photoSmall: '../img/photos/beijing/04-beijing-small.jpg',
      photo: '../img/photos/beijing/04-beijing.jpg',
      key: uuidv4(),
      filter: 'beijing',
    },
    {
      name: 'beijing-05',
      photoSmall: '../img/photos/beijing/05-beijing-small.jpg',
      photo: '../img/photos/beijing/05-beijing.jpg',
      key: uuidv4(),
      filter: 'beijing',
    },
    {
      name: 'beijing-06',
      photoSmall: '../img/photos/beijing/06-beijing-small.jpg',
      photo: '../img/photos/beijing/06-beijing.jpg',
      key: uuidv4(),
      filter: 'beijing',
    },
    {
      name: 'beijing-07',
      photoSmall: '../img/photos/beijing/07-beijing-small.jpg',
      photo: '../img/photos/beijing/07-beijing.jpg',
      key: uuidv4(),
      filter: 'beijing',
    },
    {
      name: 'beijing-08',
      photoSmall: '../img/photos/beijing/08-beijing-small.jpg',
      photo: '../img/photos/beijing/08-beijing.jpg',
      key: uuidv4(),
      filter: 'beijing',
    },
    {
      name: 'beijing-09',
      photoSmall: '../img/photos/beijing/09-beijing-small.jpg',
      photo: '../img/photos/beijing/09-beijing.jpg',
      key: uuidv4(),
      filter: 'beijing',
    },
  ],
  bucharest: [
    {
      name: 'bucharest-01',
      photoSmall: '../img/photos/bucharest/01-bucharest-small.jpg',
      photo: '../img/photos/bucharest/01-bucharest.jpg',
      key: uuidv4(),
      filter: 'bucharest',
    },
    {
      name: 'bucharest-02',
      photoSmall: '../img/photos/bucharest/02-bucharest-small.jpg',
      photo: '../img/photos/bucharest/02-bucharest.jpg',
      key: uuidv4(),
      filter: 'bucharest',
    },
    {
      name: 'bucharest-03',
      photoSmall: '../img/photos/bucharest/03-bucharest-small.jpg',
      photo: '../img/photos/bucharest/03-bucharest.jpg',
      key: uuidv4(),
      filter: 'bucharest',
    },
    {
      name: 'bucharest-04',
      photoSmall: '../img/photos/bucharest/04-bucharest-small.jpg',
      photo: '../img/photos/bucharest/04-bucharest.jpg',
      key: uuidv4(),
      filter: 'bucharest',
    },
    {
      name: 'bucharest-05',
      photoSmall: '../img/photos/bucharest/05-bucharest-small.jpg',
      photo: '../img/photos/bucharest/05-bucharest.jpg',
      key: uuidv4(),
      filter: 'bucharest',
    },
    {
      name: 'bucharest-06',
      photoSmall: '../img/photos/bucharest/06-bucharest-small.jpg',
      photo: '../img/photos/bucharest/06-bucharest.jpg',
      key: uuidv4(),
      filter: 'bucharest',
    },
    {
      name: 'bucharest-07',
      photoSmall: '../img/photos/bucharest/07-bucharest-small.jpg',
      photo: '../img/photos/bucharest/07-bucharest.jpg',
      key: uuidv4(),
      filter: 'bucharest',
    },
    {
      name: 'bucharest-08',
      photoSmall: '../img/photos/bucharest/08-bucharest-small.jpg',
      photo: '../img/photos/bucharest/08-bucharest.jpg',
      key: uuidv4(),
      filter: 'bucharest',
    },
    {
      name: 'bucharest-09',
      photoSmall: '../img/photos/bucharest/09-bucharest-small.jpg',
      photo: '../img/photos/bucharest/09-bucharest.jpg',
      key: uuidv4(),
      filter: 'bucharest',
    },
    {
      name: 'bucharest-10',
      photoSmall: '../img/photos/bucharest/10-bucharest-small.jpg',
      photo: '../img/photos/bucharest/10-bucharest.jpg',
      key: uuidv4(),
      filter: 'bucharest',
    },
    {
      name: 'bucharest-11',
      photoSmall: '../img/photos/bucharest/11-bucharest-small.jpg',
      photo: '../img/photos/bucharest/11-bucharest.jpg',
      key: uuidv4(),
      filter: 'bucharest',
    },
    {
      name: 'bucharest-12',
      photoSmall: '../img/photos/bucharest/12-bucharest-small.jpg',
      photo: '../img/photos/bucharest/12-bucharest.jpg',
      key: uuidv4(),
      filter: 'bucharest',
    },
    {
      name: 'bucharest-13',
      photoSmall: '../img/photos/bucharest/13-bucharest-small.jpg',
      photo: '../img/photos/bucharest/13-bucharest.jpg',
      key: uuidv4(),
      filter: 'bucharest',
    },
    {
      name: 'bucharest-14',
      photoSmall: '../img/photos/bucharest/14-bucharest-small.jpg',
      photo: '../img/photos/bucharest/14-bucharest.jpg',
      key: uuidv4(),
      filter: 'bucharest',
    },
    {
      name: 'bucharest-15',
      photoSmall: '../img/photos/bucharest/15-bucharest-small.jpg',
      photo: '../img/photos/bucharest/15-bucharest.jpg',
      key: uuidv4(),
      filter: 'bucharest',
    },
    {
      name: 'bucharest-16',
      photoSmall: '../img/photos/bucharest/16-bucharest-small.jpg',
      photo: '../img/photos/bucharest/16-bucharest.jpg',
      key: uuidv4(),
      filter: 'bucharest',
    },
    {
      name: 'bucharest-17',
      photoSmall: '../img/photos/bucharest/17-bucharest-small.jpg',
      photo: '../img/photos/bucharest/17-bucharest.jpg',
      key: uuidv4(),
      filter: 'bucharest',
    },
  ],
};

const photosReducer = (state = photos, action) => {
  switch (action.type) {
    default:
      return { ...state };
  }
};

export default photosReducer;
